import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
// import Image from "../components/image"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import PageWrapper from "../components/pageWrapper"

import HeroSlider from "../components/heroSlider"
import ParallaxSection from "../components/parallaxSection"

import Blockquote from "../components/blockquote"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LongTermCareInsuranceCompanies = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    
  return (
  <PageLayout>
    <SEO
      title="Long Term Care Insurance Companies | Independent Agent | LTCPG"
      ogDescription="There are many long term care insurance companies out there. Corey and his team at The Long Term Care Planning Group have relationships with them all to find the best match for you."
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h1>
            Long Term Care Insurance Companies and Strategy Overview
          </h1>
          <p>Money pays for it, health buys it. If a client cannot successfully navigate underwriting, they may not be able to secure long term care coverage on an individual basis and may have to contemplate an alternative route to manage potential long term care expenses.</p>
          <RegisterForWebinarButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/AdobeStock_318153694.jpg"
            alt="Long Term Care Insurance Companies"
          />
          <Blockquote>In the individual long term care market, clients must be fully underwritten. The long term care insurance companies review client medical history, surgical history and what medications clients have taken or are taking to determine a potential underwriting class or overall insurability for that specific carrier.</Blockquote>
        </div>
      }
    />
    <TextImageBlock
      image={
        <img
          src="/images/AdobeStock_63473501.jpg"
          alt="Long Term Care Insurance Companies - Medical History"
          className="left-point"
        />
      }
      imageRight
      imageGrow
      textPadded
      text={
        <div>
          <h2>Long Term Care Insurance Companies & Your Medical History</h2>
          <p>Client medical history may determine the solution that is chosen. If there is no medical history, there will likely be many solutions. If there is medical history, the solutions may be more limited.</p>

          <p>Each Long term care insurance company may consider certain medical history differently; and it is important to consider working with a long term care specialist that is both carrier and instrument neutral. The reason for this is that a client’s medical history may largely determine the Long term care insurance company solution that is implemented; and someone that has access at high levels to the long term care insurance company marketplace will likely provide better outcomes than someone that does not have similar access.</p>
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>Consultative Engagement</h2>
          <p>At The Long Term Care Planning Group, we create an air of consultative engagement, a concept that Corey has both embraced and taught since he became an instructor for the Long Term Care 2-Day Master Class. (www.ltc-cltc.com)  Since 2003, Corey has trained over 4,000 advisors from San Francisco to Wall Street on how to properly position long term care to their clients, friends and contacts. The Long Term Care Master Class itself entails two days of intensive training and includes education for students on Medicare, Medicaid, Tax Law, Planning Metrics, Business Development and Products that are available to clients in the Long term care insurance providers.</p>

          <p>Our organization heavily pre-qualifies clients on their health after thoroughly educating them and answering their long term care related questions, because we want to make sure we can help our clients prior to suggesting a long term care insurance company. Our long term care pre-underwriting process begins with the client completing our organization’s client intake form; the long term care Executive Application.</p>
          <ScheduleVisitButton />
        </div>
      }
      textRight={
        <div>
          <img
            src="/images/corey-rieck-ltc-specialist.jpg"
            alt="Long Term Care Insurance Companies - Consulting"
          />
        </div>
      }
    />
    <PageWrapper maxWidth color="green">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Finding The Best Long Term Care Insurance Company For You</h2>

            <p>The long term care Executive Application is a fillable pdf. Long term care insurance companies need this information to underwrite you. Once completed, we follow-up over Zoom meetings, conference calls, texts, and emails. We are proactive in clarifying any and all medical matters. We will address any concerns over the information underwriters will be reviewing. We may also ask more questions. Certain circumstances are important to address upfront before presenting to underwriters.</p>

            <p>Our goal is to ensure there are no limitations that would prevent you from securing long term care. Education, underwriting, carrier discussion, and securing coverage are all part of our process.  In our over 20 years of focus on long term care planning, we've learned how to navigate the system.</p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/AdobeStock_338280735.jpg"
              alt="Long Term Care Insurance Companies - Search"
            />
            <Blockquote>A good trial attorney never wants to venture into a courtroom not knowing as many answers as he/she can prior to doing so. Similarly, we never want to go into the Long term care insurance providers not knowing whether or not we can actually find an individual Long term care insurance company solution or solutions for the client based on their individual relevant long term care medical history.</Blockquote>
          </div>
        }
      />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Our experience is your edge. Experienced Guidance. Trusted Solutions.</h2>

            <p><a href="/about/">Our organization and teams</a> work with long term health care insurance companies. There are two main directions that a long term health care solution can be generated from—the traditional long term health care insurance companies or the Asset Based long term health care insurance companies.</p>

            <p>A client may have an existing long term health care plan and we are happy to review it with no obligation so the client is clear on their long term health care coverage metrics and gaps. Traditional long term health care insurance companies who are denoted as “Policy Holder Service Only” are not open to new clients. We will work with the other long term health care insurance companies or help you review an existing policy with your current carrier.</p>

            <p>Traditional:</p>
            <ul>
              <li>Allianz (Policy Holder Service Only)</li>
              <li>CNA (Policy Holder Service Only)</li>
              <li>Genworth (Policy Holder Service Only)</li>
              <li>Great American (Policy Holder Service Only)</li>
              <li>John Hancock (Policy Holder Service Only)</li>
              <li>Life Secure</li>
              <li>Lincoln Financial (Policy Holder Service Only)</li>
              <li>Mass Mutual</li>
              <li>Medamerica (Policy Holder Service Only)</li>
              <li>MetLife (Policy Holder Service Only)</li>
              <li>Mutual of Omaha</li>
              <li>National Guardian Life (NGL)</li>
              <li>Physicians Mutual (Policy Holder Service Only)</li>
              <li>Prudential (Policy Holder Service Only)</li>
              <li>Thrivent</li>
              <li>Transamerica</li>
              <li>True Freedom (non-insurance pre-funded home care contract)</li>
              <li>UNUM (Policy Holder Service Only)</li>                        
            </ul>

            <p>Asset Based</p>

            <ul>
              <li>Lincoln Financial</li>
              <li>Nationwide</li>
              <li>One America (State Life)</li>
              <li>Pacific Life (Pac Life)</li>
              <li>Securian (Minnesota Life)</li>
            </ul>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/corey-rieck-long-term-planning-group.jpg"
              alt="Long Term Care Insurance Companies - Custom Plans"
            />
            <Blockquote>
              <p><a href="/long-term-care-insurance-specialist/">We custom build each client’s plan</a> so that it is consistent with their needs, financial plan and funding strategy. There is a great deal of flexibility when contemplating a long term health care plan, but if the client CANNOT successfully navigate underwriting, this point is moot.</p>

              <p><a href="/long-term-care-planning/">A funding strategy</a> may also be of interest; there is a way to “pay off” a long term health care plan as you do not have to choose the “life pay” alternative. (Pay until you pass on or pay until you meet the claim long term health care claim requirements)</p>
            </Blockquote>
            <ScheduleVisitButton />
          </div>
        }
      />
      <PageWrapper maxWidth color="blue">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h2>Payment Strategies</h2>
              <ul>
                <li>Single Premium Payment</li>
                <li>Payment of the premium over:
                  <ul>
                    <li>5 years</li>
                    <li>10 years</li>
                    <li>20 years</li>
                  </ul>
                </li>
                <li>Until age 95</li>
                <li>Guaranteed premium payment strategy</li>
              </ul>

              <p>For example, Thrivent for me has been an excellent partner for hard to place clients with complicated long term health care medical history. They are an excellent carrier with sterling financial ratings that excel in the “hard to place long term health care case space” and I have gotten very good outcomes with clients that have challenging medical history.</p>

              <p>For example, OASL (One America/State Life) for me has been an excellent partner because of the leverage that they provide for multiple underwriting solutions for couples..(one is healthy, one is not—I believe that they figure that the risk is leveled out) and the fact that there is a “trap door” with the suite of annuity products if clients health doesn’t meet the Asset Care requirements. Their multiple funding strategies are valuable as well:</p>
            </div>
          }
          textRight={
            <div>
              <img
                src="/images/AdobeStock_387170870.jpg"
                alt="Long Term Care Insurance Companies - Payment Options"
              />
              <ul>
                <li>5 Pay</li>
                <li>10 Pay</li>
                <li>20 Pay</li>
                <li>Pay to age 95</li>
                <li>Guaranteed premium payment</li>
              </ul>

              <p>For example, Mutual of Omaha is valuable on the Custom Solution product because of the Cash Benefit:
              The cash benefit pays 40% of the policy’s home health benefit, up to an initial maximum of $2,400 each month.
              There’s no elimination period to satisfy before receiving the cash payment.</p>
            </div>
          }
        />
      </PageWrapper>
        <TextBlock
          textPadded
          textLeft={
        <div>
          <img
            src="/images/AdobeStock_242400048.jpg"
            alt="Long Term Care Insurance Companies - Inflation"
          />
          <Blockquote>Give us a call to ask your individual questions. We’ll confidentially discuss your long term health care planning needs or those of a loved one, <a href="tel:6788145088">(678) 814-5088</a>. This is a complimentary, no-obligation conversation.</Blockquote>
        </div>
          }
          textRight={
            <div>
              <h3>Inflation Protection Buy Up Option</h3>

              <p>Allows you to increase your inflation protection percentage once a year w/o having to prove insurability.</p>

              <h3>Security Benefit</h3>

              <p>Provides an additional 60% of your monthly reimbursement benefit that can be used to pay for care or living expenses for your uninsured partner while you receive long term health care services.</p>
              <RegisterForWebinarButton />
            </div>
          }
        />
      <QuotePanel text="Hello World" person="John Doe" />
  </PageLayout>
  )
}

export default LongTermCareInsuranceCompanies

